.uploader {
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  height: 200px;
  justify-content: center;
  outline: 3px dashed #ccc;
  outline-offset: 5px;
  position: relative;
  width: 100%;
}
.uploader input {
  display: none;
}
.uploader img,
.uploader .icon {
  pointer-events: none;
}
.uploader,
.uploader .icon {
  transition: all 100ms ease-in;
}
.uploader .icon {
  color: #eee;
  color: rgba(0, 0, 0, 0.2);
  font-size: 5em;
  width: 100px;
  z-index: 1;
  top: 40% !important;
}
.uploader img {
  left: 50%;
  opacity: 0;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transition: all 300ms ease-in;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.uploader img.loaded {
  opacity: 1 !important;
  z-index: 1;
}
.texto {
  margin-top: 175px;
}

.disabled-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #cccccc9e;
  z-index: 1;
  cursor: not-allowed;
}

/*Para imput con Crooped*/
.crop-container {
  position: relative;
  height: 222px;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.crop-upload {
  position: absolute;
  bottom: 0px;
  right: 20px;
  padding: 2px;
  z-index: 1;
  outline: 1px dashed #ccc;
  background-color: #7f7f7f;
}
div[rightImagen="70px"] {
  right: 70px !important;
}
.crop-upload img {
  width: 40px;
  cursor: pointer;
}
.crop-upload > input {
  display: none;
}

.crop-cancel {
  position: absolute;
  bottom: 0px;
  right: 20px;
  padding: 2px;
  z-index: 1;
  outline: 1px dashed #ccc;
  background-color: #7f7f7f;
}

.crop-cancel img {
  width: 37px;
  cursor: pointer;
}

.controls {
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: -25px;
}

.slider-zoom {
  padding: 30px 0px;
}

.button {
  text-align: center;
}
