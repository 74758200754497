body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-login {
  background: #00adb5 !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.expanded-container {
  /* 
  max-height: 500px !important; */
  overflow: scroll;
}

.react-bootstrap-table th {
  position: sticky;
  top: -1px;
  background-color: #fff;
}

.dark-color {
  background-color: #393e46 !important;
}

.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000 !important;
}
/* swal2-popup swal2-modal */

