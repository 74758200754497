.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icons-actions {
  color: black !important;
}

.MuiDrawer-paper {
  background: #00adb5 !important;
  color: #ffffff !important;
  font-weight: bold !important;
}

.activeMenu {
  background-color: #018e96 !important;
  
}

.mainPage {
  min-width: 100vw !important;
  min-height: 100vh !important;
}

.header-table{
  width: 100% !important;
  background-color:rgba(224, 224, 224, 1) !important;
}


.header-table .MuiDataGrid-columnHeaderTitle {
  color: #282c34 !important;  
  font-weight:  bolder !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent{
  color: #282c34 !important;  
  font-weight:  bolder !important;
}

.expand-cell-header{
  background-color: #e0e0e0 !important;
}

/***********************************/

.loginPage{
  background-color: #00adb5 !important;
  width: 100vw !important;
  height: 100vh !important;
}

.header {
  position:relative;
  background: linear-gradient(60deg, rgba(1, 142, 150, 1) 0%, #407088 100%);
  color:white;
}

.inner-header {
  height:85vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}